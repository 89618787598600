import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from './Button';

const WhatWeDo = ({items}) => {
  return (
    <section className="bg-white py-20">
      <div className="xl:container mx-auto px-6">
        <div className="text-center mb-10">
          <h3 className="text-3xl md:text-4xl font-black">What we <span className="text-sourci-pink">do</span>.</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-10 items-start">

          {items.map((i,k) => (
            <div className="items-center justify-center flex flex-col relative px-5 h-full"
              key={k}>
              <div className="absolute w-full h-full border-2 rounded-3xl top-[72px]"
                style={{
                  height: 'calc(100% - 90px)',
                  borderColor: i.border_color
                }}></div>
              <div className="rounded-full bg-slate-100 h-36 w-36 flex items-center justify-center mb-4 relative drop-shadow-md">
                <GatsbyImage image={getImage(i.icon)}
                  alt={i.icon.alt || ''}
                  width={80} />
              </div>
              <div className="text-center mb-8 grow">
                <h4 className="font-black text-2xl mb-4"
                  style={{color: i.title_color}}>{i.title}</h4>
                <div className="text-sm"
                  dangerouslySetInnerHTML={{__html: i.description.html}}></div>
              </div>
              <div className="relative w-full">
                <div className="absolute w-4 h-4 -top-2 rotate-45"
                  style={{
                    left: 'calc(50% - 0.5rem)',
                    backgroundColor: i.button_color
                  }}></div>
                <Button type="link"
                  to={`/services/${i.link_to}`}
                  label={i.button_text}
                  radius="rounded-xl"
                  padding="px-4 py-2"
                  style={{backgroundColor: i.button_color}}
                  className="text-white font-black relative w-full" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default WhatWeDo;