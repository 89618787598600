import React, {useEffect, useRef} from "react";
import { Seo } from "../components/Seo";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import * as prismicH from '@prismicio/helpers'
import Button from "../components/Button";
import Brands from "../components/Brands";
import WhatWeDo from "../components/WhatWeDo";
import ContactForm from "../components/ContactForm";
import ReactPlayer from "react-player";
import Carousel from "../components/Projects/Carousel";

const ContactSection = ({slug}) => {
  return (
    <section className="bg-[#f8f8f8] py-32">
      <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
        <div className="text-black">
          <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
          <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
          <p className="text-3xl mb-2">
            <a href="tel:+611300768724"
              className="hover:underline">1300 SOURCI</a>
          </p>
          <p className="text-3xl mb-2">
            <a href="mailto:hello@sourci.com.au"
              className="hover:underline">hello@sourci.com.au</a>
          </p>
        </div>
        <div className="mt-12 lg:mt-0">
          <ContactForm bg="light"
            location={`Landing - ${slug}`} />
        </div>
      </div>
    </section>
  )
}

const LandingTemplate = ({pageContext}) => {
  const textRef = useRef();
  const imageRef = useRef();
  const data = pageContext.data;

  const resizeSection = () => {
    if(textRef.current.querySelector('div') || false) {
      let mheight = textRef.current.offsetHeight + 280;
      imageRef.current.style.maxHeight = `${mheight}px`;
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const prismicData = useStaticQuery(graphql`
    query {
      prismicLayout {
        data {
          logo {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
    }
  `);
  return (
    <main>
      <div className="bg-sourci-teal">
        <div className="xl:container mx-auto">
          <div className="flex items-center pl-4 py-8">
            <GatsbyImage image={getImage(prismicData.prismicLayout.data.logo)}
              alt="Sourci Logo" />
          </div>
        </div>
      </div>
      <section>
        <div className="grid lg:grid-cols-2">
          <div className="py-24 px-16 xl:px-32"
            ref={textRef}>
            <div>
              <h1 className="font-black text-black text-4xl mb-8">
                {data.header_line_1 && <span className="block">{data.header_line_1}</span>}
                {data.header_line_2 && <span className="block">{data.header_line_2}</span>}
                {data.header_line_3 && <span className="block">{data.header_line_3}</span>}
              </h1>
            </div>
            <div className="landingContent mb-8"
              dangerouslySetInnerHTML={{ __html: prismicH.asHTML(data.body_text) }} />
            <div>
              <Button label={data.cta_button_text ?? 'Push'}
                className="text-white bg-sourci-teal text-lg md:text-xl px-16 py-2.5"
                type="link"
                to={data.cta_button_link ?? '/contact'} />
            </div>
          </div>
          <div className="flex"
            ref={imageRef}>
            <img src={data.image}
              alt={data.image_alt}
              className="object-cover min-w-[100%] lg:min-w-[50vw] lg:w-[50vw]" />
          </div>
        </div>
      </section>

      <Brands logos={data.brands} bg={data.services ? 'bg-slate-100' : 'bg-white'} />
      {data.services && <WhatWeDo items={data.services} />}
      {data.show_elly_section && 
        <section className="py-24 bg-[#ed76bc]">
          <div className="xl:container mx-auto px-6">
            <div className="grid lg:grid-cols-2 gap-y-6 md:gap-x-40 gap-x-60 w-full justify-center items-center">
              <div>
                <div className="mx-auto flex overflow-hidden rounded-3xl relative pt-[56.25%]">
                  <ReactPlayer url="https://www.youtube.com/watch?v=VeQQU2yqOaI"
                    controls
                    className="absolute top-0 left-0"
                    width="100%"
                    height="100%"
                    config={{
                      youtube: {
                          playerVars: {
                              modestbranding: 1,
                          },
                      },
                    }} />
                </div>
              </div>
              <div>
                <h4 className="font-black text-white text-4xl mb-6">What We Do</h4>
                <p className="text-white text-xl lg:text-2xl">We partner with scaling & established E-commerce brands and their leaders to help navigate the complex world of sourcing. To access reliable factories, the required minimum spend on production should be at least AUD$20,000. This ensures partnership with quality, reputable and compliant manufacturers.</p>
              </div>
            </div>
          </div>
        </section>}
      {data.show_contact_form && <ContactSection slug={data.slug} />}
      <Carousel projects={data.projects}
        title="Amazing Projects"
        description="See some of the projects we've been working on with our fantastic brands">
        {data.show_cta_in_projects_section && (
          <div className="lg:container mx-auto text-center">
            <Button label={data.cta_button_text}
              className="text-white bg-sourci-teal text-lg px-10 py-2.5"
              type="link"
              to={data.cta_button_link} />
          </div>
        )}
      </Carousel>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl mb-6">Our Process</h3>
          <p className="text-3xl lg:text-4xl font-black text-sourci-teal mb-12">
            The four-point Sourci system to help you scale at speed.
          </p>
          <div className="hidden lg:block">
            <StaticImage src="../images/process_diagram.png"
              alt="Our 4 step process" />
          </div>
          <div className="lg:hidden grid md:grid-cols-2 gap-y-8 gap-x-4">
            <StaticImage src="../images/process_diagram_one.png"
              alt="Step one" />
            <StaticImage src="../images/process_diagram_two.png"
              alt="Step two" />
            <StaticImage src="../images/process_diagram_three.png"
              alt="Step three" />
            <StaticImage src="../images/process_diagram_four.png"
              alt="Step four" />
          </div>
        </div>
      </section>
    </main>
  )
}

export default LandingTemplate;

export function Head({pageContext}) {
  return <Seo title={pageContext.data.browser_title}
    canonical={`/landing/${pageContext.data.slug}`} />
}